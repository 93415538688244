import {
  useEffect,
  useState,
} from 'react';

import Snowfall from 'react-snowfall';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import Carousel from './Carousel';
import {
  Gc,
  Gi,
  Tb1,
  Tc,
  Th1,
  Th4,
} from './TG';

const otherVids = [
  "yMV04cziBh8", // Count and the Organ 12 Days
  "ysIzPF3BfpQ", // Muppet Carol of the Bells
  // "DhVjZqqDlBg", // Muppet 12 days
  "GJLAFNYCE_c", // Muppet Jingle Bell Rock
  // "IvO07TQ-4sc", // Muppet feels like christmas
  // "T0226u_blWQ", // Emmet Otter Brothers in Our World
  "Sd5PEVKuAro", // Sesame Street Bob Keep Christmas With You
  "nQPWDjfe4MI", // skating
  // "mMl4Pls41qI", // white christmas
  "l2oPio60mK4", // bob doug (replaced 2021)
  // "nLGBEETtEPc", // olaf that time of year
  "3x_qMcIRbdU", // marillion
  "P8CxQbyhifQ", // Guardians holiday special song
  "GF2BzUDeTkY", // Sting Gabriel's Message
  // "ALYzoesVybI", // snow is lightly falling
  // "IUkwXavUhm4", // squire-white run with the fox
  "3zpKrUIXw9Q", // Oldfield In Dulci Jubilo
  "yfY4b1NszpY", // lake - i believe in father christmas
  "ZhRUDQbFzpg", // Tull We Five Kings
  "VdugkldCx10", // when we're together
];
const whamVid = "E8gmARGvPlI"; // eslint-disable-line
const rickrollVid = "dQw4w9WgXcQ"; // eslint-disable-line

const allVids = [...otherVids, whamVid];

const vidCode = p => {
  const a = 1; // p === whamVid || p === rickrollVid ? 1 : 0;
  return {
    thumb: `https://img.youtube.com/vi/${p}/hqdefault.jpg`,
    iframeSrc: `https://www.youtube.com/embed/${p}?autoplay=${a}`
  };
};

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: "5vw",
    paddingRight: "5vw",
    paddingTop: "5vh",
    paddingBottom: "5vh",
    height: "calc(100vh - 64px)",
    alignItems: "center"
  },
  sliderPaper: {
    padding: 24,
    backgroundColor: "#000000"
  },
  gb: {
    border: "2px outset #2e7d32"
  },
  gr: {
    border: "2px inset #b71c1c",
    color: "#ffffff"
  },
  gsw: {
    display: "flex",
    justifyContent: "center"
  },
  sw: {
    transform: "rotate(90deg) scale(2.5)",
    top: 24
  }
}));

function Main() {
  const classes = useStyles();

  const [current, setCurrent] = useState(null);
  const [spin, setSpin] = useState(null);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  useEffect(() => {
    if (spin === null) return;

    setTimeout(() => {
      const newCurrent = ((current || 0) + spin) % allVids.length;
      setSpin(null);
      setCurrent(newCurrent);
    }, 500);
  }, [spin, current]);

  useEffect(() => {
    const p = allVids[current];
    const a = p === whamVid || p === rickrollVid ? 1 : 0;
    if (a) {
      setSwitchDisabled(true);
      setTimeout(() => {
        setSwitchDisabled(false);
      }, 10000);
    }
  }, [current]);

  const fireAway = () => {
    if (spin !== null) return;
    let theSpin = Math.floor(Math.random() * 180) + 21;
    setSpin(theSpin);
  };

  const isSwitchOn = spin !== null;

  const frame = (
    <iframe
      title="video"
      id="ytplayer"
      type="text/html"
      frameBorder="0"
      allow="autoplay"
      src={vidCode(allVids[current]).iframeSrc}
    ></iframe>
  );

  const instructions = (
    <div className="helper">
      <Th4>Spin The Wheel!</Th4>
      <p />
      <Tb1>
        Flip the switch to get your random holiday-themed video. If Whamageddon
        occurs, well, there we are. Keep playing until you're out or until you
        have seen and hopefully enjoyed the other videos, which are some of my
        favorite seasonal works.
      </Tb1>
      <p />
      <Tc color="primary">
        Little Drummer Boy is not included in the spinner, so you're safe from
        being kicked out of that game if you play this.
      </Tc>
    </div>
  );
  const spinning = (
    <Th1 align="center" color="secondary">
      SPINNING!
    </Th1>
  );

  const carouselProps = {
    spin,
    current,
    vidCode,
    allVids
  };

  return (
    <>
      <Snowfall />
      <Gc spacing={10} className={classes.main}>
        <Gi xs={8} md={3}>
          <Paper
            elevation={3}
            className={
              classes.sliderPaper + " " + classes.gb + " carousel-wrapper"
            }
          >
            <Carousel {...carouselProps} />
          </Paper>
        </Gi>
        <Gi xs={4} md={2} className={classes.gsw}>
          <Switch
            disabled={switchDisabled}
            className={classes.sw}
            color="primary"
            checked={isSwitchOn}
            onChange={fireAway}
          />
        </Gi>
        <Gi xs={12} md={7}>
          <Paper className={classes.sliderPaper + " " + classes.gr}>
            <div
              className={
                "embed-container" +
                (!isSwitchOn ? (current !== null ? "" : " instructions") : "")
              }
            >
              {!isSwitchOn
                ? current !== null
                  ? frame
                  : instructions
                : spinning}
            </div>
          </Paper>
        </Gi>
      </Gc>
    </>
  );
}

export default Main;
